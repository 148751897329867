/* Calculator.css */

.container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: auto;
    max-width: 60%;
    max-height: 100%;
    overflow: auto;
    text-align: center;
    padding-top: 0px;
    background-color: #f0f8ff;
    border-radius: 4px;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
    margin: auto;
    margin-top: 0px;
    padding: 20px;
}

.title {
    margin-bottom: 20px;
}

.content {
    width: 100%;
}
